import React from 'react'
import ErrorPageContainer from 'components/ErrorPageContainer'
import { Box, Text } from '@chakra-ui/react';
import { responsive } from 'contexts/responsive';
import RatioImage from 'components/RatioImage';
import Button from 'components/Button';
import Link from 'next/link';
import arrow from '../images/arrow_right.png';
import removed from '../images/removed.svg';

export const Btn = ({ children, href, ...props }) => (
  <Link href={href}>
    <Button
      _hover={{}}
      _focus={{}}
      _active={{}}
      px="1rem"
      py="0.625rem"
      height="auto"
      width="100%"
      minWidth="auto"
      color="black"
      fontSize={responsive('1.125em')}
      border="1px solid"
      bg="transparent"
      rightIcon={
        <Box width="1.5rem">
          <RatioImage ratio={1} src={arrow} />
        </Box>
      }
      {...props}
    >
      {children}
    </Button>
  </Link>
);


const QuizIsNotOpen = () => {
  return (
    <ErrorPageContainer>
      <Box
        px={responsive('1.5em', '1.5em')}
        width={responsive('100%', '51%')}
        maxW={responsive('19.25rem', '100%')}
        mx="auto"
      >
        <RatioImage src={removed} ratio={1} alt="not-exist" />
      </Box>
      <Box px={responsive('1.5em', '1.5em')} width={responsive('100%', '46%')}>
        <Box
          as="h2"
          fontWeight={700}
          pt="0.625em"
          fontSize={'1.5em'}
          letterSpacing={'0.027em'}
        >
          本測驗/遊戲已經由製作者下架囉！
        </Box>
        <Text fontSize={'1em'} fontWeight={500} my={responsive('0.625rem')}>
          歡迎到超開放實驗室官方網站上查看更多有趣的範例
        </Text>
        <Box width="100%">
          <Btn href="/">回到超開放實驗室首頁</Btn>
        </Box>
      </Box>
    </ErrorPageContainer>
  )
}

export default QuizIsNotOpen
