import {
  Center,
  Container,
  Flex,
} from '@chakra-ui/react';
import geometry from 'containers/images/geometry.svg';
import { responsive } from 'contexts/responsive';

function ErrorPageContainer({ children }) {
  return (
    <Center
      backgroundImage={geometry.src}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize={['224%', '100%']}
      backgroundColor="#FDC65A"
      minHeight={'100vh'}
    >
      <Container
        as={Flex}
        flexDir={responsive('column', 'row')}
        maxW={responsive('100%', '50em')}
        px={0}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {children}
      </Container>
    </Center>
  );
}

export default ErrorPageContainer;
