import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import fof from '../images/modules/404.png';
import RatioImage from 'components/RatioImage';
import { responsive } from 'contexts/responsive';
import ErrorPageContainer from 'components/ErrorPageContainer';
import { Btn } from 'containers/Quiz/QuizIsNotOpen';

export default function FourOhFour({ children, title }) {
  return (
    <ErrorPageContainer>
      <Box
        px={responsive('1.5em', '1.5em')}
        width={responsive('100%', '51%')}
        maxW={responsive('19.25rem', '100%')}
        mx="auto"
      >
        <RatioImage src={fof} ratio={1} alt="oops" />
      </Box>
      <Box px={responsive('1.5em', '1.5em')} width={responsive('100%', '46%')}>
        <Heading as="h1" fontSize={responsive('5rem', '6.25rem')}>
          {title || 404}
        </Heading>
        {children}
        <Box
          as="h2"
          fontWeight={700}
          borderTop={'1px solid'}
          pt="0.625em"
          fontSize={'1.5em'}
          letterSpacing={'0.027em'}
        >
          OOPS！你好像不小心進入了未知的頁面！
        </Box>
        <Text fontSize={'1em'} fontWeight={500} my={responsive('0.625rem')}>
          讓我帶你回到原本的世界，繼續探索各種有趣的遊戲與測驗！
        </Text>
        <HStack
          width={'100%'}
          pt={2}
          spacing={6}
          justifyContent={'space-between'}
        >
          <Box width="50%">
            <Btn href="/">回到首頁</Btn>
          </Box>
          <Box width="50%">
            <Btn href="/#about">聯繫客服</Btn>
          </Box>
        </HStack>
      </Box>
    </ErrorPageContainer>
  );
}
